import React from "react";
import styled from "styled-components";
import { useStaticQuery, Link, graphql } from "gatsby";
import Logo from "../img/logo300px.png";
import RedCrossImg from "../img/cross.png";
import LinkedinImg from "../img/Linkedin.png";
import MailImg from "../img/Mail.png";

const Navbar = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  grid-template-rows: auto;
  grid-template-areas:
    "logo . topNav"
    "logo . mainNav";
  align-items: center;
  margin: 19px 0 33px 0;
`;

const LogoArea = styled.div`
  grid-area: logo;
  justify-self: start;
`;
const TopNav = styled.div`
  grid-area: topNav;
  justify-self: end;
`;
const MainNav = styled.div`
  grid-area: mainNav;
  justify-self: end;
  text-align: right;
`;

const TopNavAnchor = styled.a`
  font-size: 11px;
  font-weight: 600;
  color: #999;
  text-decoration: none;
  line-height: 14px;
  margin: 0 0 0 22px;
`;

const TopNavLink = styled(Link)`
  font-size: 11px;
  font-weight: 600;
  color: #999;
  text-decoration: none;
  line-height: 14px;
  margin: 0 0 0 22px;
`;

const MainNavLink = styled(Link)`
  text-decoration: none;
  white-space: nowrap;
  margin: 0 0 0 20px;
  padding: 8px 0 8px 25px;
  font-weight: bold;
  letter-spacing: -1px;
  color: #444;
  text-transform: uppercase;
`;

const Navigation = () => {
  const data = useStaticQuery(navigationQuery);

  const {
    allMarkdownRemark: { nodes }
  } = data;

  return (
    <Navbar>
      <LogoArea>
        <Link to="/">
          <img src={Logo} alt="CUTBUSTERS by Baptiste Cummings" />
        </Link>
      </LogoArea>
      <TopNav>
        <TopNavAnchor href="/admin">Client Access</TopNavAnchor>
        <TopNavLink to="/about">About</TopNavLink>
        <TopNavLink as="a" href="mailto:baptiste@cutbusters.com?Subject=Hello">
          <img src={MailImg} width={18} height={13} alt="Mail Icon" />
        </TopNavLink>
        <TopNavLink as="a" href="http://www.linkedin.com/in/baptistecummings">
          <img src={LinkedinImg} width={15} height={14} alt="LinkedIn Icon" />
        </TopNavLink>
      </TopNav>
      {/* <MainNav>
        {nodes
          .sort((a, b) => a.frontmatter.order - b.frontmatter.order)
          .map(node => (
            <MainNavLink
              key={node.fields.slug}
              to={node.fields.slug}
              activeStyle={{
                background: `url(${RedCrossImg}) no-repeat`,
                backgroundPosition: "6% 42%"
              }}
            >
              {node.frontmatter.title}
            </MainNavLink>
          ))}
      </MainNav> */}
    </Navbar>
  );
};

export default Navigation;

export const navigationQuery = graphql`
  query NavigationComponent {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "category-entry" } } }
    ) {
      nodes {
        fields {
          slug
        }
        frontmatter {
          title
          order
        }
      }
    }
  }
`;
