import React, {useEffect} from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import useSiteMetadata from "./SiteMetadata";
import Navigation from "./Navigation";
import Footer from "./Footer";
import GlobalStyle from "../css/globalStyles";

const Container = styled.div`
  width: 850px;
  min-height: 200px;
  margin: 0 auto;
  text-align: left;
`;

const TemplateWrapper = ({ children }) => {
  const { title, description } = useSiteMetadata();

  useEffect(() => {
    console.log(`
Modernized & ported from Wordpress to JAMstack by 
__  __ ____  __  __  
|  \/  |  _ \|  \/  | 
| \  / | |_) | \  / | 
| |\/| |  _ <| |\/| | 
| |  | | |_) | |  | | 
|_|  |_|____/|_|  |_| 
                  
https://embiem.me`
    );
  }, [])

  return (
    <Container>
      <GlobalStyle />
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />
      </Helmet>
      <Navigation />
      {children}
      <Footer />
    </Container>
  );
};

export default TemplateWrapper;
