import { createGlobalStyle } from "styled-components";
import { GtmedTTF } from "./fonts";

export default createGlobalStyle`
@font-face {
  font-family: "gtmed";
  font-weight: normal;
  font-style: normal;
  src: url(${GtmedTTF}) format("truetype");
}

@import url('https://fonts.googleapis.com/css?family=Maven+Pro:400,700');

body {
  font-family: 'Maven Pro', gtmed, Arial, Helvetica, Tahoma, sans-serif;
  font-size: 12px;
  text-align: center;
  color: #888;
}
`;
