import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  border-top: 1px solid rgba(51, 51, 51, 0.1);
`;

const LaPlanceSpan = styled.span`
  float: right;
  a {
    color: #888;
  }
`;

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <StyledFooter>
      © {year} CUTBUSTERS BY BAPTISTE CUMMINGS{" "}
      <LaPlanceSpan>
        Design by <a href="http://www.laplanchedesign.com/">LaPlanche</a>
      </LaPlanceSpan>
    </StyledFooter>
  );
};

export default Footer;
